import React from "react"
import Layout from "../../../components/layout"
import ProductCard from "../../../components/ProductCard"
import "../../../css/products.css"
import AOS from "aos"
import LProfilImg from "../../../images/lprofil.jpg"
import UNosacImg from "../../../images/unosac.jpg"
import INosacImg from "../../../images/inosac.jpg"
import IPENosacImg from "../../../images/ipenosac.jpg"
import IPBNosacImg from "../../../images/ipbnosac.jpg"
import IPBLNosacImg from "../../../images/ipb-lnosac.jpg"

import ProductsLayout from "../../../components/productsLayout"
import { useEffect } from "react"
import ServiceCard from "../../../components/ServiceCard"
import Head from "../../../components/head"

const IndexPage = () => {
  useEffect(() => {
    // Aos
    AOS.init({
      once: true,
    })
  })
  return (
    <Layout>
      <Head title="Profili i nosači" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Profili i Nosači</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Profili i Nosači
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="products-list">
            <div className="columns is-mobile is-multiline" data-aos="zoom-in">
              <ProductCard
                title="Toplovaljani jednokraki ugaonici - L profili"
                img={LProfilImg}
                to="/proizvodi/profili-nosači/l-profili"
              />
              <ProductCard
                title="Toplovaljani nosači - U"
                img={UNosacImg}
                to="/proizvodi/profili-nosači/u-nosači"
              />
              <ProductCard
                title="Toplovaljani nosači - I"
                img={INosacImg}
                to="/proizvodi/profili-nosači/i-nosači"
              />
              <ProductCard
                title="Toplovaljani nosači - IPE"
                img={IPENosacImg}
                to="/proizvodi/profili-nosači/ipe-nosači"
              />
              <ProductCard
                title="Toplovaljani nosači - IPB - HEB"
                img={IPBNosacImg}
                to="/proizvodi/profili-nosači/ipb-heb-nosači"
              />
              <ProductCard
                title="Toplovaljani nosači - IPB-L - HEA"
                img={IPBLNosacImg}
                to="/proizvodi/profili-nosači/ipb-l-nosači"
              />
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default IndexPage
